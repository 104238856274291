export default ({ pricing, locations, industries, companySizes, activeCampaigns }) => ({
    pricing,
    locations,
    industries,
    companySizes,
    activeCampaigns,

    activeLocation: "",

    orders: [],

    init() {
        this.activeLocation = Object.keys(this.locations)[0];
    },

    toggleAddToOrder(location, industry, companySize) {
        if (!this.orderExists(location, industry, companySize)) {
            this.addToOrder(location, industry, companySize);
        } else {
            this.removeFromOrder(location, industry, companySize);
        }
    },

    addToOrder(location, industry, companySize) {
        if (!this.orderExists(location, industry, companySize)) {
            const price = this.priceForCampaign(companySize);
            this.orders.push({ location, industry, companySize, price });
        }
    },

    removeFromOrder(location, industry, companySize) {
        this.orders = this.orders.filter(
            (o) => !(o.location === location && o.industry === industry && o.companySize === companySize),
        );
    },

    orderSummaryString(order) {
        return `${this.locations[order.location]}, ${this.industries.find((i) => i.id === order.industry).name}, ${this.companySizes[order.companySize].name}`;
    },

    orderExists(location, industry, companySize) {
        return (
            this.orders.filter(
                (o) => o.location === location && o.industry === industry && o.companySize === companySize,
            ).length > 0
        );
    },

    isAlreadyActive(location, industry, companySize) {
        return (
            this.activeCampaigns.filter(
                (o) => o.location === location && o.industry === industry && o.companySize === companySize,
            ).length > 0
        );
    },

    get orderSubtotal() {
        return this.orders.reduce((acc, cur) => acc + cur.price, 0);
    },

    get orderTaxes() {
        return this.orderSubtotal * 0.1;
    },

    get orderTotal() {
        return this.orderSubtotal + this.orderTaxes;
    },

    get orderFormContent() {
        return JSON.stringify(this.orders);
    },

    priceForCampaign(companySize) {
        return this.pricing[companySize.toLowerCase()] * 10;
    },

    formatUSD(amount) {
        const formatter = Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
        return formatter.format(amount);
    },
});
